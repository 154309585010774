import { CountryCode } from "@/common/config"
import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { z } from "zod"
import { ICountry } from "@/client/constants/constants"
export {
	getCountryDate,
	getTimeZone,
	getDisplayTimeZone,
	formatDateToDateString,
	getFormattedLocalTime,
	compareDatesIgnoreTime,
	getDefaultDatesForAnnouncementPage,
} from "./date-time-utils"

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

/**
 * Transform the heading to a more readable format
 * default separator is space
 */
export const transformHeading = (tab: string, separator: string = " ") => {
	return tab
		.split(separator)
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ")
}

export const validateCountryCode = (countryCode: string) => {
	try {
		const validCountryCode = z.nativeEnum(CountryCode)
		validCountryCode.parse(countryCode.toUpperCase())
		return true
	} catch (error) {
		return false
	}
}

export function filterContent(content: string) {
	const regex = /<\/?(img|image|a)\b[^>]*>/gi
	return content.replace(regex, "")
}

export function slugify_array(my_array: (string | number)[]) {
	return my_array
		.filter(str => str)
		.map(
			str =>
				str
					.toString()
					.trim()
					.replace(/[^\w\s-]/g, "") // remove non alphanumeric characters, spaces, dashes
					.replace(/[\s_-]+/g, "-") // replace word separators (spaces, underscores, dashes) with a single dash
		)
		.join("-")
		.toLowerCase()
		.replace(/^-+|-+/g, "-") // replace consecutive dashes with a single dash
}

export function getPdfLink(pdfLink: string, pageNo: number) {
	return `${pdfLink}#page=${pageNo}`
}

export function extractFromData(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any[] | undefined,
	type: string,
	value: string
) {
	if (!Array.isArray(data) || !Array.isArray(data[data.length - 1])) {
		return null
	}
	const innerArray = data[data.length - 1]
	for (let i = 0; i < innerArray.length; i++) {
		const item = innerArray[i]
		if (item && item.type === type) {
			return item[value] || null
		}
	}
	return null
}

export const capitalizeWords = (name: string | null | undefined): string => {
	if (!name) {
		return ""
	}
	return name
		.split(" ")
		.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(" ")
}

export const truncateText = (text: string, maxLength: number) => {
	return text.length > maxLength ? text.substring(0, maxLength) + "..." : text
}

export const getMarketCapDisplay = (id: string, countryCode: ICountry) => {
	if (countryCode === "us") {
		switch (id) {
			case "mega":
				return "Mega Cap (> $200B)"
			case "large":
				return "Large Cap ($10B - $200B)"
			case "mid":
				return "Mid Cap ($2B - $10B)"
			case "small":
				return "Small Cap ($300M - $2B)"
			case "micro":
				return "Micro Cap ($50M - $300M)"
			case "nano":
				return "Nano Cap (< $50M)"
			default:
				return id
		}
	} else {
		switch (id) {
			case "large":
				return "Large Cap (> ₹20,000 Cr)"
			case "mid":
				return "Mid Cap (₹5,000 Cr - ₹20,000 Cr)"
			case "small":
				return "Small Cap (₹500 Cr - ₹5,000 Cr)"
			case "micro":
				return "Micro Cap (₹100 Cr - ₹500 Cr)"
			case "nano":
				return "Nano Cap (< ₹100 Cr)"
			default:
				return id
		}
	}
}
