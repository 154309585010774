export const getCountryDate = (countryCode: string, date: Date): Date => {
	return new Date(
		new Date(date).toLocaleString("en-US", {
			timeZone: getTimeZone(countryCode),
		})
	)
}

export const getTimeZone = (countryCode: string) => {
	const timeZone: Record<string, string> = {
		// TODO: Use single casing for country code in whole codebase
		IN: "Asia/Kolkata",
		US: "America/New_York",
		in: "Asia/Kolkata",
		us: "America/New_York",
	}
	return timeZone[countryCode]
}

export const getDisplayTimeZone = (countryCode: string) => {
	const timeZone: Record<string, string> = {
		IN: "IST",
		US: "ET",
	}
	return timeZone[countryCode]
}

export const formatDateToDateString = (date: string, countryCode: string): string => {
	const options: Intl.DateTimeFormatOptions = {
		timeZone: getTimeZone(countryCode.toUpperCase()),
		month: "short",
		day: "numeric",
		year: "numeric",
	}

	const formatter = new Intl.DateTimeFormat("en-US", options)
	const formattedDate = formatter.format(new Date(date))

	return `${formattedDate}`
}

export const getFormattedLocalTime = (date: Date | string, countryCode: string) => {
	const dateObj = date instanceof Date ? date : new Date(date)

	if (isNaN(dateObj.getTime())) {
		throw new Error("Invalid date provided")
	}

	const options: Intl.DateTimeFormatOptions = {
		timeZone: getTimeZone(countryCode.toUpperCase()),
		hour: "2-digit",
		minute: "2-digit",
		hour12: false,
	}

	const timeFormatter = new Intl.DateTimeFormat("en-US", options)
	const formattedTime = timeFormatter.format(dateObj)
	const timeZoneAbbr = getDisplayTimeZone(countryCode.toUpperCase())

	return `${formattedTime} ${timeZoneAbbr}`
}

export function compareDatesIgnoreTime(date1: Date, date2: Date): boolean {
	const date1WithoutTime = new Date(date1)
	const date2WithoutTime = new Date(date2)

	date1WithoutTime.setHours(0, 0, 0, 0)
	date2WithoutTime.setHours(0, 0, 0, 0)

	return date1WithoutTime.getTime() === date2WithoutTime.getTime()
}

export function formatDateDDMonthShortYY(publishedDate: string, countryCode: string): string {
	const options: Intl.DateTimeFormatOptions = {
		timeZone: getTimeZone(countryCode),
		month: "short",
		day: "numeric",
		year: "2-digit",
	}
	const formatter = new Intl.DateTimeFormat("en-US", options)
	const formattedDate = formatter.format(new Date(publishedDate))
	const formattedDateParts = formattedDate.split(" ")
	const month = formattedDateParts[0]
	const day = formattedDateParts[1].replace(",", "") // Change "4," to "4"
	const year = formattedDateParts[2]
	return `${day} ${month} '${year}`
}

export function getDefaultDatesForAnnouncementPage(countryCode: string) {
	const today = new Date()
	const yesterday = new Date(getCountryDate(countryCode.toUpperCase(), today))
	yesterday.setDate(today.getDate() - 1)
	yesterday.setHours(0, 0, 0, 0)

	return { from_date: yesterday, to_date: today }
}
